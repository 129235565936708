<template>
  <div class="common-layout">
    <el-container id = "main" style="height: 100%">
      <el-header>Header</el-header>
      <el-container>
        <el-aside width="300px" >
          <div class="tree-container">
            <el-tree class="custom-tree" :data="tree" node-key="id" @node-click="handleNodeClick" ></el-tree>
          </div>
        </el-aside>
        <el-main style="padding: 0px;">
          <div align="left" style="position: relative" v-if="markdownPath">
            <div class="view-div1"><el-button class="edit-btn" @click="handleClick">编辑</el-button></div>
            <div class="view-div2"><MdPreview v-model="markdown"></MdPreview></div>
          </div>
        </el-main>
      </el-container>
      <el-footer>Footer</el-footer>
    </el-container>
  </div>

</template>

<script>
import router from '@/router';
import 'md-editor-v3/lib/style.css';
import axios from '@/api/axios'; // 引入封装好的api
import { MdPreview } from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';


export default {
  components: {
    MdPreview
  },

  async mounted() {
    const navData = await axios.post("/md/query/nav")
    this.tree = navData.data;

    if (this.markdownPath) {
      axios.post("/md/query/markdown",{
        "path": this.markdownPath
      }).then(response => {
        this.markdown = response.data.data;
      }).catch(error => {
        console.error('请求失败:', error);
        this.markdown = ''
      });
    }
  },

  data() {
    return {
      tree:[],
      markdown: '',
      clickNode:null,
    };
  },
  methods: {
    handleNodeClick(data) {
      this.clickNode = data;
      if (data.path) {
        axios.post("/md/query/markdown",{
          "path": "/"+data.path
        }).then(response => {
          this.markdown = response.data.data;
        }).catch(error => {
          console.error('请求失败:', error);
          this.markdown = ''
        });
      }
      
      if (data.path){
        router.push('/views/'+data.path);
      }
    },

    // 定义点击事件的处理函数
    handleClick() {
      router.push({
        path:'/edit/'+this.$route.params.path,
        query:{
          id:this.clickNode.id,
        }
      });
    },

  },
  computed:{
    markdownPath:{
      get(){
        return this.$route.params.path== undefined ? '':`/${this.$route.params.path}`;
      }
    },
  }
};



</script>

<style>
.common-layout{
  height: 100%;
  bottom: 0;
}

.main{
  height: 100%;
  bottom: 0;
  text-align: left;
}

.el-header {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-footer{
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
  bottom: 0;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.tree-container {
  height: 100%;
  border-right: 1px solid #dcdfe6; /* 根据需要设置边框颜色 */
  overflow-y: auto;
  overflow-x: auto;
  background-color: #ffffff;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 30px;
}

.el-aside::-webkit-scrollbar {
  display: none;
}

.view-div1 {
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #dcdfe6; /* 根据需要设置边框颜色 */

}

.view-div2{
  position: absolute;
  top: 50px;
  width: 100%;
}

.edit-btn{
  position: absolute;
  right: 20px;
  top:20px;
}

.md-editor-preview figure{
  margin: 0px;
}

.default-theme img{
  border: 0px !important;
  border-radius: 0px !important;
}

.md-editor-preview h1{
  border-bottom: 1px solid #eaecef;
  padding-bottom: .3rem;
  margin: .83em 0;
}


.md-editor-preview h2{
  border-bottom: 1px solid #eaecef;
  padding-bottom: .3rem;
  margin: .83em 0;
}

</style>

